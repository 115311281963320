import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import ButtonClaims from './../../claims/button';
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
interface ChipData {
  label: string;
}
interface Document {
  id: string;
  name: string;
  permissionEmails: string;
  documentId: string;
}

const EditDocument: FunctionComponent<any> = ({
  idTemplate,
  setLoader,
}): JSX.Element => {
  const effectRan = useRef(false);
  const [emails, setEmails] = useState<ChipData[]>([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [ulrDoc, setUrlDoc] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [disabledMail, setDisabledMail] = useState(true);

  useEffect(() => {
    if (effectRan.current === false) {
      const getDataInitial = async () => {
        setLoader(true);
        const client = new DocumentTemplatesClient();
        await client.enabledPermission({ id: idTemplate });
        const docs = await client.getAllTemplates();
        const doc = docs.find((x) => x.id === idTemplate);
        setName(doc.name);
        const ulrd = `https://docs.google.com/document/d/${doc.documentId}/edit`;
        setUrlDoc(ulrd);
        const mails = doc.permissionEmails;
        setEmails((prevEmails) => [
          ...prevEmails,
          ...mails.map((str) => ({ label: str })),
        ]);
        setLoader(false);
      };

      getDataInitial();
      effectRan.current = true;
    }
  }, []);

  useEffect(() => {
    setDisabled(!(emails.length > 0 && name));
  }, [emails, name]);
  useEffect(() => {
    setDisabledMail(
      !(
        /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) ||
        /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/.test(email)
      ),
    );
  }, [email]);
  const handleAddEmail = () => {
    const data = email;
    const emailsList = emails;
    if (emailsList.indexOf({ label: data }) === -1) {
      setEmails((prevEmails) => [...prevEmails, ...[{ label: data }]]);
      setEmail('');
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setEmails((chips) => chips.filter((chip) => chip.label !== chipToDelete));
  };
  const changeEmail = (event) => {
    setEmail(event?.target.value);
  };
  const onReturn = () => {
    window.location.replace('/documentTemplates');
  };
  const onUpdateTemplate = async () => {
    const mails = emails.map(({ label }) => label);
    const data = {
      id: idTemplate,
      name: name,
      documentTypeId: 0,
      permissionEmails: mails,
      copyId: null,
    };

    const client = new DocumentTemplatesClient();
    await client.updateTemplate(data);
    await client.removePermissions(idTemplate);

    window.location.replace('/documentTemplates');
  };

  return (
    <>
      <Button onClick={onReturn}>Volver</Button>
      <Typography className={'gridCard-claim__title'}>
        Editar Plantilla
      </Typography>
      <TextField value={name} label="Nombre del documento" />
      <TextField label="Nuevo correo" value={email} onChange={changeEmail} />
      <ButtonClaims
        onClick={handleAddEmail}
        disabled={disabledMail}
        text="Agregar correo"
      />

      <Typography>Listado de correos con acceso</Typography>
      {emails ? (
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {emails.map((data, index) => {
            return (
              <ListItem key={index}>
                <Chip label={data.label} onDelete={handleDelete(data.label)} />
              </ListItem>
            );
          })}
        </Paper>
      ) : null}
      <br />
      <iframe src={ulrDoc} style={{ width: '100%', height: '100%' }} />
      <ButtonClaims
        disabled={disabled}
        text="Actualizar plantilla"
        onClick={onUpdateTemplate}
      />
    </>
  );
};

export default EditDocument;
