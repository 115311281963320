import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';
import { ActionType } from 'types/action';
import ButtonClaims from './../../claims/button';
import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import ConnectServicesClient from 'shared/clients/ConnectServiceClient';
import ClaimTrackerClient from 'shared/clients/ClaimsTrackerClient';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';
import { IInspectionContext, InspectionContext } from 'context/inspections';

interface ClaimStepsProps {
  claim: any;
  uId: string;
}
moment.locale('es');
const SendDocument: FunctionComponent<ClaimStepsProps> = ({
  claim,
  uId,
}): JSX.Element => {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const effectRan = useRef(false);
  const [documents, setDocuments] = useState<any>([]);
  const [template, setTemplate] = useState<any>(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (effectRan.current === false) {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      const getDataInitial = async () => {
        const docs = await new DocumentTemplatesClient().getAllTemplates();
        setDocuments(docs);
      };

      getDataInitial();

      dispatch({ type: ActionType.SET_LOADING, payload: false });
      effectRan.current = true;
    }
  }, []);
  useEffect(() => {
    setDisabled(!(template && subject && message));
  }, [template, subject, message]);
  const changeTemplate = (event) => {
    setTemplate(event?.target.value);
  };

  const changeSubject = (event) => {
    setSubject(event?.target.value);
  };
  const changeMessage = (event) => {
    setMessage(event?.target.value);
  };
  const tryParseInt = (str, defaultValue) => {
    let retValue = defaultValue;
    if (str !== null) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }

    return retValue;
  };
  const handleSendMail = async () => {
    dispatch({ type: ActionType.SET_LOADING, payload: true });
    const vari = await new ConnectServicesClient().getVariablesTemplate(
      '6',
      template,
    );
    const varAll = await new DocumentTemplatesClient().getVariables(template);
    const result = extractData(vari, varAll);
    const pdf = await new DocumentTemplatesClient().generatePDF({
      id: template,
      parameters: result,
    });
    const send = await new ClaimTrackerClient().sendDocumentTemplate({
      id: uId,
      subject: subject,
      message: message,
      pdf: pdf,
    });

    dispatch({ type: ActionType.SET_LOADING, payload: false });
  };

  const extractData = (variables, valAll) => {
    const result = {};
    valAll.forEach(function (v) {
      if (v.startsWith('currentDate')) {
        const date = moment().format(
          v.startsWith('currentDate(')
            ? v.replace('currentDate(', '').replace(')', '')
            : 'MM/DD/YYYY',
        );
        result[v] = date;
      } else {
        const v1 = variables.data.find((x) => x.variable === v);
        const props = v1.fieldId.split('.');
        const inte = tryParseInt(props[0], 0);
        if (inte === 0) {
          let valu = claim.info;
          props.forEach(function (p) {
            valu = valu[p];
          });
          result[v1.variable] = valu;
          v1.value = valu;
        } else {
          const step = claim.steps.find((x) => x.id === parseInt(props[0]));
          const section = step.sections.find(
            (x) => x.id === parseInt(props[1]),
          );
          const field = section.containers[0].fields.find(
            (x) => x.key === parseInt(props[2]),
          ).defaultValue;
          result[v1.variable] = field;
          v1.value = field;
        }
      }
    });

    return result;
  };

  return (
    <Box sx={{ paddingTop: '1rem' }}>
      <label>Enviar documento</label>
      <br />
      <br />
      <Grid container spacing={2} className="sendDocument">
        <Grid item xs={3}>
          <FormControl sx={{ minWidth: 320 }}>
            <InputLabel>Seleccione plantilla</InputLabel>
            <Select
              label="Seleccione plantilla"
              value={template}
              autoWidth
              onChange={changeTemplate}
            >
              {documents.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <TextField
            label="Asunto"
            value={subject}
            style={{ width: '90%' }}
            onChange={changeSubject}
          />
          <br />
          <br />
          <TextField
            style={{ width: '90%' }}
            label="Mensaje"
            multiline
            rows={4}
            value={message}
            onChange={changeMessage}
          />
          <br />
          <br />
          <ButtonClaims
            disabled={disabled}
            style={{ position: 'relative', float: 'right', marginRight: '10%' }}
            onClick={handleSendMail}
            text="Enviar documento"
          />
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SendDocument;
