export enum FilterState {
  FILTER_CANCELLED = 8,
  FILTER_DELETED = 9,
  FILTER_TOTAL_LOSS = 12,
  FILTER_SUBROGATION = 13,
  FILTER_PENDING_APPROVAL = 15,
  FILTER_PENDING_SINISTER = 17,
  FILTER_FINALIZED_SINITER = 18,
  FILTER_PENDING_INSURANCE = 14,
}
