import { AxiosResponse } from 'axios';
import {
  FieldTypes,
  ValueTypes,
} from 'components/inspection/section/InputField';
import { format, add, parseISO } from 'date-fns';
import PdfIcon from 'assets/images/pdf.svg';
import { set } from 'lodash';
import APIClient, { endpoints } from 'shared/api';
import { AAPRequest, AAPResponse, AAPValidationResponse } from 'types/aap';
import {
  mergeObjectData,
  requestChangeImageOrientantion,
  transformToUpperCase,
  validateDate,
} from 'shared/utils';
import { ActionType, DispatchFunction } from 'types/action';
import { Event } from 'types/event';
import { EventNote } from 'types/event-note';
import { EventPhoto, PhotoSection } from 'types/images';
import { FieldSection } from 'types/section-fields';
import {
  AltaReporteInput,
  AltaReporteOutput,
  AltaSiniestroInput,
  AltaSiniestroOutput,
  QualitasUpdateModel,
  CoveragesRequest,
} from 'types/AltaReporteInput';
import {
  IInspection,
  IInspectionState,
  inspectionDefault,
  IThird,
  thirdDefault,
  transitionDefault,
  IAditionalInfo,
  AditionalInfoDefault,
  IConfigData,
  ConfigDataDefault,
  IInjuryPerson,
  injuryPersonDefault,
  ICoverage,
  coverageDefault,
  consequenceDefault,
  qualitasCoveragesDefault,
  IAuditEvent,
} from 'types/inspection';
import { CancelTransition, EventDownloadEvent } from './inspectionTypes';
import { EVENT_STATUSES } from 'static/constants/event-status';
import { Agent, IUserModel } from 'types/user';
import {
  getDataFromImageUrlPDF417,
  SceneTypes,
} from '@connect-technology/connect-react-components-lib';
import {
  BrandsId,
  CountryCode,
  CountryIds,
  DocumentType,
  FormType,
  IRotateImage,
  ITransition,
  ITriggerAction,
  MetadataTag,
  NoteType,
  Rol,
  TransitionStatesIds,
} from 'types/common';
import { Field } from 'types/form-field';
import { Params } from 'context/filters/FiltersContext';
import { IClaim, IEventData, IRequestForm } from 'types/claimsTracker';
import ClaimTrackerClient from 'shared/clients/ClaimsTrackerClient';
import ConnectServicesClient from 'shared/clients/ConnectServiceClient';
import axiosRetry from 'axios-retry';
import { EventReminder } from 'types/event-reminder';
import { sicSaveQueue } from 'shared/utils/requestQueue';
const USES_EXPRESS_ADJUST = [11, 15, 1]; //11 - CSM, 15 - SURA, 1 - Qualitas

interface Client {
  id: string;
  licenseNumber: string;
  firstName: string;
  lastName: string;
  address: string;
  birthDate: string;
  expirationDate: string;
  licenseType: string;
  fullName: string;
}

interface ImageDataExtract {
  DriverFirstName?: string;
  DriverLastName?: string;
  Name?: string;
  IdCard?: string;
  DriverLicenseNumber?: string;
  DriverBirthDate?: string;
  DriverLicenseType?: string;
  DriverLicenseExpirationDate?: string;
  Brand?: string;
  Color?: string;
  InsuredAmount?: string;
  Model?: string;
  Motor?: string;
  Plate?: string;
  Transmission?: string;
  Type?: string;
  Vin?: string;
  Year?: string;
}

// Get Inspection list events
export const getInspections = async (
  params: { [key: string]: any },
  dispatch: DispatchFunction,
  fectClaims?: boolean,
): Promise<void> => {
  try {
    const userCompanyId = params.companyId || localStorage.getItem('companyId');
    const userRolId = localStorage.getItem('rolId');
    const countryCode = localStorage.getItem('countryCode');
    const inspectorId = localStorage.getItem('inspectorId') ?? '0';
    const runnerCode = localStorage.getItem('runnerCode');
    const customerCode = localStorage.getItem('customerCode');
    if (
      countryCode !== undefined &&
      countryCode != null &&
      countryCode?.length > 0
    ) {
      // Validate if inspector
      if (+inspectorId > 0) {
        params = { ...params, inspectorId: inspectorId };
      }
      // Validate if runner
      if (runnerCode && String(runnerCode).length > 0) {
        params = { ...params, runnerCode: runnerCode };
      }

      // Validate if a customer user
      if (customerCode && String(customerCode).length > 0) {
        params = { ...params, customerCode: customerCode };
      }

      dispatch({
        type: ActionType.GET_PAGINATOR_SUCCESS,
        payload: {
          paginator: { rows: 0, initial: 0, final: 0 },
        },
      });

      // Validate agent
      const agentId = params.showMyServices
        ? localStorage.getItem('userId')
        : null;
      delete params.showMyServices;

      const endpoint = fectClaims
        ? endpoints.GET_INSPECTIONS_CLAIMS
        : endpoints.GET_INSPECTIONS;

      const companyIdList = localStorage.getItem('companyIdList') || undefined;
      const companyId =
        userRolId === '1' && !params.companyId ? null : userCompanyId;
      const parameters = {
        ...params,
        companyId: companyIdList && !params.companyId ? undefined : companyId,
        rolId: userRolId,
        page: params.page + 1 || 1,
        countryCode: countryCode,
        agentId,
        statusId:
          params.filterByTotalLoss || params.filterBySubrogation
            ? undefined
            : params.statusId,
        companyIdList: companyIdList,
      };
      const response = await APIClient.get(endpoint, {
        params: parameters,
      });

      if (response) {
        if (fectClaims) {
          getPaginator(dispatch, parameters);
        }
        dispatch({
          type: ActionType.GET_EVENTS_SUCCESS,
          payload: {
            events: response.data.data.response.events,
            indicators: response.data.data.response.indicators,
          },
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ActionType.GET_EVENTS_FAIL,
      payload: 'No se pudo obtener la lista de inspecciones',
    });
  }
};

const getPaginator = async (
  dispatch: DispatchFunction,
  params: { [key: string]: any },
): Promise<void> => {
  const responsePaginator = await APIClient.get(
    endpoints.GET_INSPECTIONS_CLAIMS_PAGINATOR,
    {
      params: params,
    },
  );
  if (responsePaginator) {
    dispatch({
      type: ActionType.GET_PAGINATOR_SUCCESS,
      payload: {
        paginator: responsePaginator.data.data.response,
      },
    });
  }
};

// Update transcription Indicator
export const updateTranscriptInd = async (id: number): Promise<void> => {
  await APIClient.post(
    endpoints.POST_EVENT_TRANSCRIPT.replace('{id}', String(id)),
  );
};

// Get Inspection Event by Id
export const getInspectionById = async (
  id: number,
  dispatch: DispatchFunction,
  isInspector = false,
): Promise<IInspection | null> => {
  try {
    const response = await APIClient.get(
      endpoints.GET_INSPECTION.replace('{id}', String(id)),
    );
    if (response && response.data?.data) {
      let currentEvent: IInspection = { ...inspectionDefault };
      const inspection = response.data.data;
      const eventData = inspection?.event;
      const accidentData = inspection?.accident;
      const deposits = inspection?.Deposits;
      const optima = inspection?.optima;
      const listVehicles = inspection?.vehicles;
      const listTransitions = inspection?.transitions;
      const listThirds = inspection?.thirds;
      const listOptima = inspection?.AditionalInfo;
      const listInjuryPersons = inspection?.injuryPersons;
      const listCoverages = inspection?.coverages;
      const listConfigData = inspection?.configdata;
      const listCoveragesQualitas = inspection?.qualitasCoverages;
      const guia = inspection?.guia?.[0];

      const insuredVeh: any = listVehicles
        ? Array.from(listVehicles).filter(
            (f: any) => f?.vehicle?.NumeroVehiculo === 1,
          )[0]
        : [];

      const injuredVeh: any = listVehicles
        ? Array.from(listVehicles).filter(
            (f: any) => f?.vehicle?.NumeroVehiculo === 2,
          )[0]
        : [];

      const transitions: ITransition[] = [];
      if (listTransitions) {
        Array.from(listTransitions).forEach((tran: any) => {
          let transition: ITransition = { ...transitionDefault };
          transition = mergeObjectData(tran.transition, transition);
          transitions.push(transition);
        });
      }

      const thirds: IThird[] = [];
      if (listThirds) {
        Array.from(listThirds).forEach((tran: any) => {
          let third: IThird = { ...thirdDefault };
          third = mergeObjectData(tran.third, third);
          thirds.push(third);
        });
      }

      const injuryPersons: IInjuryPerson[] = [];
      if (listInjuryPersons) {
        Array.from(listInjuryPersons).forEach((injObj: any) => {
          let person: IInjuryPerson = { ...injuryPersonDefault };
          person = mergeObjectData(injObj, person);
          injuryPersons.push(person);
        });
      }

      const coverages: ICoverage[] = [];
      if (listCoverages) {
        Array.from(listCoverages).forEach((cvjObj: any) => {
          let coverage: ICoverage = { ...coverageDefault };
          coverage = mergeObjectData(cvjObj, coverage);
          coverages.push(coverage);
        });
      }

      const coveragesQualitas: ICoverage[] = [];
      if (listCoveragesQualitas) {
        Array.from(listCoveragesQualitas).forEach((cvjObj: any) => {
          let coverageQualitas: ICoverage = { ...qualitasCoveragesDefault };
          coverageQualitas = mergeObjectData(cvjObj, coverageQualitas);
          coveragesQualitas.push(coverageQualitas);
        });
      }

      // Assign event and accident data
      currentEvent =
        eventData !== undefined
          ? mergeObjectData(eventData, currentEvent)
          : currentEvent;
      currentEvent =
        accidentData !== undefined
          ? mergeObjectData(accidentData, currentEvent)
          : currentEvent;

      // Assign Owner data
      currentEvent.InsuredOwner =
        eventData !== undefined
          ? mergeObjectData(eventData, currentEvent.InsuredOwner)
          : currentEvent.InsuredOwner;

      currentEvent.InsuredOwner =
        accidentData !== undefined
          ? mergeObjectData(accidentData, currentEvent.InsuredOwner)
          : currentEvent.InsuredOwner;

      currentEvent.InsuredOwner =
        insuredVeh['vehicle'] !== undefined
          ? mergeObjectData(insuredVeh['vehicle'], currentEvent.InsuredOwner)
          : currentEvent.InsuredOwner;

      currentEvent.InjuredOwner =
        eventData !== undefined
          ? mergeObjectData(eventData, currentEvent.InjuredOwner)
          : currentEvent.InjuredOwner;

      currentEvent.InjuredOwner =
        accidentData !== undefined
          ? mergeObjectData(accidentData, currentEvent.InjuredOwner)
          : currentEvent.InjuredOwner;

      currentEvent.InjuredOwner =
        injuredVeh['vehicle'] !== undefined
          ? mergeObjectData(injuredVeh['vehicle'], currentEvent.InjuredOwner)
          : currentEvent.InjuredOwner;

      // Assign Driver data
      currentEvent.InsuredDriver =
        eventData !== undefined
          ? mergeObjectData(eventData, currentEvent.InsuredDriver)
          : currentEvent.InsuredDriver;

      currentEvent.InsuredDriver =
        accidentData !== undefined
          ? mergeObjectData(accidentData, currentEvent.InsuredDriver)
          : currentEvent.InsuredDriver;

      currentEvent.InsuredDriver =
        insuredVeh['vehicle'] !== undefined
          ? mergeObjectData(insuredVeh['vehicle'], currentEvent.InsuredDriver)
          : currentEvent.InsuredDriver;

      currentEvent.InjuredDriver =
        eventData !== undefined
          ? mergeObjectData(eventData, currentEvent.InjuredDriver)
          : currentEvent.InjuredDriver;

      currentEvent.InjuredDriver =
        accidentData !== undefined
          ? mergeObjectData(accidentData, currentEvent.InjuredDriver)
          : currentEvent.InjuredDriver;

      currentEvent.InjuredDriver =
        injuredVeh['vehicle'] !== undefined
          ? mergeObjectData(injuredVeh['vehicle'], currentEvent.InjuredDriver)
          : currentEvent.InjuredDriver;

      // Assign vehicles data
      currentEvent.InsuredVehicle =
        eventData !== undefined
          ? mergeObjectData(eventData, currentEvent.InsuredVehicle)
          : currentEvent.InsuredVehicle;

      currentEvent.InsuredVehicle =
        accidentData !== undefined
          ? mergeObjectData(accidentData, currentEvent.InsuredVehicle)
          : currentEvent.InsuredVehicle;

      currentEvent.InsuredVehicle =
        insuredVeh['vehicle'] !== undefined
          ? mergeObjectData(insuredVeh['vehicle'], currentEvent.InsuredVehicle)
          : currentEvent.InsuredVehicle;

      currentEvent.InjuredVehicle =
        eventData !== undefined
          ? mergeObjectData(eventData, currentEvent.InjuredVehicle)
          : currentEvent.InjuredVehicle;

      currentEvent.InjuredVehicle =
        accidentData !== undefined
          ? mergeObjectData(accidentData, currentEvent.InjuredVehicle)
          : currentEvent.InjuredVehicle;

      currentEvent.InjuredVehicle =
        injuredVeh['vehicle'] !== undefined
          ? mergeObjectData(injuredVeh['vehicle'], currentEvent.InjuredVehicle)
          : currentEvent.InjuredVehicle;

      // Assign Optima Data
      currentEvent.optima =
        optima !== undefined
          ? mergeObjectData(optima?.[0] || {}, currentEvent.optima)
          : currentEvent.optima;

      // Assign Guia Data
      currentEvent.guia =
        guia !== undefined
          ? mergeObjectData(guia, currentEvent.guia)
          : currentEvent.guia;

      const ConfigData: IConfigData[] = [];
      if (listConfigData) {
        Array.from(listConfigData).map((tran: any) => {
          let data: IConfigData = { ...ConfigDataDefault };
          data = mergeObjectData(tran, data);
          ConfigData.push(data);

          return true;
        });
      }

      const AditionalInfo: IAditionalInfo[] = [];
      if (listOptima) {
        Array.from(listOptima).map((tran: any) => {
          let infoAditional: IAditionalInfo = { ...AditionalInfoDefault };
          infoAditional = mergeObjectData(tran, infoAditional);
          AditionalInfo.push(infoAditional);

          return true;
        });
      }

      //Consequences AAP
      currentEvent.consequence = consequenceDefault;
      currentEvent.consequence.CodigoAsegurado =
        eventData.insuredCodeConsequences;
      currentEvent.consequence.CodigoTercero = eventData.thirdCodeConsequences;
      currentEvent.consequence.Responsable = eventData.responsableConsequences;

      // Assign transitions and deposits
      currentEvent = {
        ...currentEvent,
        Transitions: transitions,
        thirds: thirds,
        Deposits: deposits,
        AditionalInfo: AditionalInfo,
        configdata: ConfigData,
        injuryPersons: injuryPersons,
        coverages:
          BrandsId.QUALITAS === currentEvent.CompanyId
            ? coveragesQualitas.filter((f: any) => f.Selected === true)
            : coverages,
        qualitasCoverages: coveragesQualitas,
        ShouldUseExpressAdjust: USES_EXPRESS_ADJUST.includes(
          Number(currentEvent.CompanyId),
        ),
      };

      // Validate Claims Tracker reference
      if (!isInspector) {
        await getClaimsFromClaimTracker(currentEvent, dispatch);
      }

      dispatch({
        type: ActionType.SET_CURRENT_INSPECTION,
        payload: currentEvent,
      });

      return currentEvent;
    }
  } catch (error) {
    dispatch({
      type: ActionType.GET_EVENTS_FAIL,
      payload: 'No se pudo obtener la inspección solicitada.',
    });
  }

  return null;
};

export const getClaimsFromClaimTracker = async (
  inspection: IInspection,
  dispatch: DispatchFunction,
): Promise<void> => {
  if (inspection.EnableClaimsTracker) {
    const arrClaims: IClaim[] = [];

    if (inspection.CountryCode === CountryCode.PRI.toUpperCase()) {
      const insuredClaimsTrackerId = inspection.InsuredOwner?.ClaimsTrackerId;
      const injuredClaimsTrackerId = inspection.InjuredOwner?.ClaimsTrackerId;

      if (insuredClaimsTrackerId && insuredClaimsTrackerId.length > 0) {
        let resultClaim = await getClaimById(insuredClaimsTrackerId);
        if (resultClaim !== undefined) {
          resultClaim = { ...resultClaim, eventId: inspection.EventId };
          arrClaims.push(resultClaim);
        }
      }

      if (injuredClaimsTrackerId && injuredClaimsTrackerId.length > 0) {
        let resultClaim = await getClaimById(injuredClaimsTrackerId);
        if (resultClaim !== undefined) {
          resultClaim = { ...resultClaim, eventId: inspection.EventId };
          arrClaims.push(resultClaim);
        }
      }
    }

    dispatch({
      type: ActionType.SET_CLAIMS_TRACKER_DATA,
      payload: arrClaims,
    });
  }
};

export const getClaimById = async (UId: string): Promise<IClaim> => {
  return await new ClaimTrackerClient().getClaim(UId);
};

export const addOrUpdateClaimRequest = async (
  claimRequestId: string | undefined,
  claimUId: string,
  createdBy: string,
  description: string,
  notify = false,
): Promise<string> => {
  return await new ClaimTrackerClient().addOrUpdateClaimRequest(
    claimRequestId,
    claimUId,
    createdBy,
    description,
    notify,
  );
};

export const updateClaimStatusById = async (UId: string): Promise<string> => {
  return await new ClaimTrackerClient().setNextStatusByClaim(UId);
};

export const removeClaimRequest = async (
  claimRequestId: string | undefined,
): Promise<string> => {
  return await new ClaimTrackerClient().removeClaimRequest(
    claimRequestId ?? '',
  );
};

export const notifyClaimRequest = async (UId: string): Promise<boolean> => {
  return await new ClaimTrackerClient().notifyClaimRequest(UId);
};

// Get Form configuration
export const getFormConfiguration = async (
  params: { [key: string]: any },
  dispatch: DispatchFunction,
): Promise<FieldSection[] | null> => {
  try {
    const {
      data: {
        data: { sections },
      },
    } = await APIClient.get(endpoints.GET_FORM_CONFIG, {
      params,
    });

    return sections;
  } catch (error) {
    dispatch({
      type: ActionType.GET_EVENTS_FAIL,
      payload: 'No se pudo obtener la configuración del formulario',
    });
  }

  return null;
};

// Get Event Reminders
export const getReminders = async (
  eventId: number,
): Promise<Array<EventReminder>> => {
  const response = await APIClient.get(
    endpoints.GET_INSPECTION_REMINDERS.replace('{id}', eventId.toString()),
  );

  return response.data.data;
};

// Get Event Notes
export const getNotes = async (eventId: number): Promise<Array<EventNote>> => {
  const response = await APIClient.get(
    endpoints.GET_INSPECTION_NOTES.replace('{id}', eventId.toString()),
  );

  return response.data.data;
};

// Get Event Notes by Type
export const getNotesByType = async (
  eventId: number,
  noteType: number,
): Promise<Array<EventNote>> => {
  const response = await APIClient.get(
    endpoints.GET_INSPECTION_NOTES_TYPE.replace('{id}', eventId.toString()) +
      '?TypeId=' +
      noteType,
  );

  return response.data.data;
};

// Add audit log
export const AddAuditLog = async (
  eventId: number,
  userId: number,
  message: string,
): Promise<void> => {
  const data = {
    EventId: eventId,
    UserId: userId,
    Message: message,
  };
  const response = await APIClient.post(endpoints.POST_ADD_AUDITLOGS, data);

  return response.data.data;
};

// Get AAP Data
export const getAAPData = async (params: AAPRequest): Promise<AAPResponse> => {
  const response = await APIClient.post(endpoints.GET_AAP, params);

  return response.data.data;
};

// Get Validate AAP
export const validateAAP = async (
  param: string | undefined,
): Promise<AAPValidationResponse | undefined> => {
  if (param === undefined) {
    return undefined;
  }

  const response = await APIClient.get(
    endpoints.GET_VALIDATE_AAP.replace('{id}', param),
  );

  return response.data.data as AAPValidationResponse;
};

// Siniestralidad Qualitas
export const getSiniestrar = async (eventId: string): Promise<string> => {
  const response = await APIClient.get(
    endpoints.GET_SINIESTRAR.replace('{id}', String(eventId)),
  );

  return response.data.data;
};

export const postAltaReporte = async (
  params: AltaReporteInput,
): Promise<AltaReporteOutput> => {
  const response = await APIClient.post(endpoints.POST_ALTA_REPORTE, params);

  return response.data.data;
};

export const postAltaSiniestro = async (
  params: AltaSiniestroInput,
): Promise<AltaSiniestroOutput> => {
  const response = await APIClient.post(endpoints.POST_ALTA_SINIESTRO, params);

  return response.data.data;
};

export const updateQualitas = async (
  params: QualitasUpdateModel,
): Promise<void> => {
  await APIClient.post(endpoints.POST_UPDATE_QUALITAS, params);
};

export const updateQualitasCoverages = async (
  params: CoveragesRequest,
): Promise<void> => {
  await APIClient.post(endpoints.POST_UPDATE_COVERAGES_QUALITAS, params);
};

// Post Event Notes
export const saveNote = async (
  eventId: number,
  comment: { [key: string]: any },
  noteTypeId?: number,
): Promise<boolean> => {
  const attended =
    (Number(localStorage.getItem('rolId')) === Rol.INSURER ||
      Number(localStorage.getItem('rolId')) === Rol.CLAIMS_ASSISTANT) &&
    !(
      Number(localStorage.getItem('companyId')) === BrandsId.SURA ||
      Number(localStorage.getItem('companyId')) === BrandsId.BANESCO
    );

  const response = await APIClient.post(
    endpoints.POST_INSPECTION_NOTES.replace('{id}', eventId.toString()),
    { ...comment, attended, noteTypeId },
  );

  return response.statusText === 'OK';
};

// Post Event Reminder
export const saveReminder = async (
  eventId: number,
  comment: { [key: string]: any },
): Promise<boolean> => {
  const response = await APIClient.post(
    endpoints.POST_INSPECTION_REMINDERS.replace('{id}', eventId.toString()),
    { ...comment },
  );

  return response.statusText === 'OK';
};

export const editNote = async (
  eventId: number,
  commentId: number,
  comment: { [key: string]: any },
): Promise<boolean> => {
  const attended =
    (Number(localStorage.getItem('rolId')) === Rol.INSURER ||
      Number(localStorage.getItem('rolId')) === Rol.CLAIMS_ASSISTANT) &&
    !(
      Number(localStorage.getItem('companyId')) === BrandsId.SURA ||
      Number(localStorage.getItem('companyId')) === BrandsId.BANESCO
    );

  const response = await APIClient.put(
    endpoints.PUT_INSPECTION_NOTES.replace('{id}', eventId.toString()).replace(
      '{commentId}',
      commentId.toString(),
    ),
    { commentId, ...comment, attended },
  );

  return response.statusText === 'OK';
};

// Put Event Reminder
export const updateReminder = async (
  eventId: number,
  reminderId: number,
  comment: { [key: string]: any },
): Promise<boolean> => {
  const response = await APIClient.put(
    endpoints.PUT_INSPECTION_REMINDERS.replace(
      '{id}',
      eventId.toString(),
    ).replace('{reminderId}', reminderId.toString()),
    { ...comment },
  );

  return response.statusText === 'OK';
};

// Send PDF to client
export const sendClientPdf = async (eventRecord: string): Promise<string> => {
  const response = await APIClient.post(
    endpoints.POST_SEND_PDF.replace('{id}', eventRecord),
  );

  return response.data.data;
};

const getBase64PDF = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadAudio = async (
  file: File,
  eventNumber: string,
  countryCode: string,
) => {
  const formData = new FormData();
  formData.append(file.name, file);
  const endpoint = endpoints.POST_AUDIO.replace(
    '{countryCode}',
    countryCode,
  ).replace('{eventRecord}', eventNumber);

  const result = await APIClient.post(endpoint, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  return result.data.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const presignedAudioUrl = async (url: string) => {
  const result = await APIClient.post(endpoints.PRESIGNED_AUDIO_URL, {
    url: url,
  });

  return result.data.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transcriptAudioUrl = async (url: string, countryCode: string) => {
  const endpoint = endpoints.TRANSCRIPT_AUDIO_URL.replace(
    '{countryCode}',
    countryCode,
  );

  const result = await APIClient.post(endpoint, {
    url: url,
  });

  return result.data.data;
};

export const normalizeText = (text: string): string => {
  const textClean = text.replace(/\s/g, '');

  const normalizeString = textClean
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return normalizeString;
};

// Upload files using MEDIA Endpoint
export const uploadMediaFile = async (
  file: File,
  EventRecord: string,
  Sectionid: number,
  BucketName: string | null = null,
  BucketRegion: string | null = null,
  claimAmountId: string | null = null,
  metadataTag?: MetadataTag,
  documentType?: string,
  user?: string,
  role?: string,
): Promise<EventPhoto> => {
  const base64Str = await getBase64PDF(file);
  const formData = new FormData();
  formData.append('FolderRoot', EventRecord);

  let fileName =
    String(Sectionid) +
    '_' +
    normalizeText(file.name.substring(0, file.name.length - 4));

  if (claimAmountId) {
    fileName =
      String(Sectionid) +
      '_' +
      claimAmountId +
      '_' +
      normalizeText(file.name.substring(0, file.name.length - 4));
  }

  switch (file.type) {
    case 'application/pdf':
      formData.append('FileExtension', 'pdf');
      break;
    case 'video/webm':
      formData.append('FileExtension', 'webm');
      fileName = fileName.replace('.', '');
      break;
    case 'image/jpeg':
      formData.append('FileExtension', 'jpeg');
      fileName = fileName.replace('.', '');
      break;
    case 'image/png':
      formData.append('FileExtension', 'png');
      fileName = fileName.replace('.', '');
      break;
    default:
      formData.append('FileExtension', 'pdf');
  }

  formData.append('FileName', fileName);
  formData.append('FileDataBase64', String(base64Str));

  if (BucketName && BucketRegion) {
    formData.append('BucketName', BucketName);
    formData.append('BucketRegion', BucketRegion);
  }

  if (metadataTag) {
    formData.append('metadataTag', metadataTag);
  }

  if (documentType) {
    formData.append('DocumentType', documentType);
  }

  if (user) {
    formData.append('User', user);
  }

  if (role) {
    formData.append('Role', role);
  }

  const result = await APIClient.post(endpoints.POST_MEDIA_UPLOAD, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  const imageDefault: EventPhoto = {
    dataURL: String(base64Str),
    awsUrl: PdfIcon,
    imageId: 0,
    imageName:
      String(Sectionid) + '_' + file.name.substring(0, file.name.length - 4),
    section: Number(Sectionid),
    imageType: 'pdf',
    order: 1,
    urlUpload: result?.data?.data,
  };

  return imageDefault;
};

// Upload files using MEDIA Endpoint
export const uploadMediaFileBase64 = async (
  file: string,
  fileName: string,
  EventRecord: string,
  Sectionid: number,
  BucketName: string | null = null,
  BucketRegion: string | null = null,
  claimAmountId: string | null = null,
  metadataTag?: MetadataTag,
  documentType?: string,
  user?: string,
  role?: string,
): Promise<EventPhoto> => {
  const formData = new FormData();
  formData.append('FolderRoot', EventRecord);

  if (claimAmountId) {
    fileName =
      String(Sectionid) + '_' + claimAmountId + '_' + normalizeText(fileName);
  }
  formData.append('FileExtension', 'pdf');

  formData.append('FileName', fileName);
  formData.append('FileDataBase64', file);

  if (BucketName && BucketRegion) {
    formData.append('BucketName', BucketName);
    formData.append('BucketRegion', BucketRegion);
  }

  if (metadataTag) {
    formData.append('metadataTag', metadataTag);
  }

  if (documentType) {
    formData.append('DocumentType', documentType);
  }

  if (user) {
    formData.append('User', user);
  }

  if (role) {
    formData.append('Role', role);
  }

  const result = await APIClient.post(endpoints.POST_MEDIA_UPLOAD, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  const imageDefault: EventPhoto = {
    dataURL: file,
    awsUrl: PdfIcon,
    imageId: 0,
    imageName: String(Sectionid) + '_' + fileName,
    section: Number(Sectionid),
    imageType: 'pdf',
    order: 1,
    urlUpload: result?.data?.data,
  };

  return imageDefault;
};

// Remove media file for specific resource
export const deleteMediaFiles = async (resource: string): Promise<boolean> => {
  const response = await APIClient.post(endpoints.DELETE_MEDIA_FILES, {
    fileURL: resource,
  });

  return response.data.data;
};

const updateDataEvent = async (
  countryCode: string,
  eventRecord,
  data: Client,
) => {
  const payload: ImageDataExtract = {
    DriverFirstName: data.firstName.replaceAll('_', ' '),
    DriverLastName: data.lastName.replaceAll('_', ' '),
    IdCard: data.id,
    DriverLicenseNumber: data.licenseNumber,
    DriverBirthDate: data.birthDate,
    DriverLicenseType: data.licenseType,
    DriverLicenseExpirationDate: data.expirationDate,
  };

  const endpoint = endpoints.POST_UPDATE_DATA_EVENT.replace(
    '{countryCode}',
    CountryIds[countryCode],
  ).replace('{eventRecord}', eventRecord);

  await APIClient.post(endpoint, payload);
};

const fieldsName = (value: string) => {
  switch (value) {
    case 'brand':
      return 'brand';
    case 'model':
      return 'model';
    case 'year':
      return 'year';
    case 'color':
      return 'color';
    case 'plate':
      return 'plate';
    case 'vin':
      return 'vehicleVIN';
    case 'motor':
      return 'vehicleEngine';
    default:
      return '';
  }
};

export const setValueFieldName = (
  fieldName: string,
  sections: FieldSection[] | undefined,
  value: string,
  setSections?: (sections: FieldSection[]) => void,
): void => {
  if (sections && setSections) {
    const field = sections
      ?.map((section) =>
        section.fields.find((field) => field.name === fieldName),
      )
      .find((field) => field?.name === fieldName);
    if (field) {
      field.value = value;
    }
    setSections(sections);
  }
};

const fetchDataDriverLicense = async (
  image: EventPhoto,
  urlImage: string,
  countryCode: string,
  eventRecord: string,
  dispatch: DispatchFunction,
  setSections?: (sections: FieldSection[]) => void,
  sections?: FieldSection[],
) => {
  if (image.section === PhotoSection.DRIVER_LICENSE_BACK) {
    try {
      const result = await getDataFromImageUrlPDF417(urlImage, countryCode);
      if (result) {
        await updateDataEvent(countryCode, eventRecord, result);
        dispatch({
          type: ActionType.SET_DRIVER_NAME,
          payload: result.firstName.replaceAll('_', ' '),
        });
        setValueFieldName(
          'driverName',
          sections,
          result.firstName.replaceAll('_', ' '),
          setSections,
        );
        dispatch({
          type: ActionType.SET_DRIVER_LAST_NAME,
          payload: result.lastName.replaceAll('_', ' '),
        });
        setValueFieldName(
          'driverLastName',
          sections,
          result.lastName.replaceAll('_', ' '),
          setSections,
        );
        dispatch({
          type: ActionType.SET_DRIVER_ID,
          payload: result.id,
        });
        setValueFieldName('driverId', sections, result.id, setSections);
        dispatch({
          type: ActionType.SET_DRIVER_LICENSE_NUMBER,
          payload: result.licenseNumber,
        });
        setValueFieldName(
          'driverNumLicense',
          sections,
          result.licenseNumber,
          setSections,
        );
        dispatch({
          type: ActionType.SET_DRIVER_LICENSE_TYPE,
          payload: result.licenseType,
        });
        setValueFieldName(
          'driverLicenseType',
          sections,
          result.licenseType,
          setSections,
        );
        dispatch({
          type: ActionType.SET_DRIVER_LICENSE_EXPIRATION,
          payload: result.expirationDate + 'T00:00:00',
        });
        setValueFieldName(
          'driverExpireLicenseDate',
          sections,
          result.expirationDate + 'T00:00:00',
          setSections,
        );
        dispatch({
          type: ActionType.SET_DRIVER_BIRTH_DATE,
          payload: result.birthDate + 'T00:00:00',
        });
        setValueFieldName(
          'driverBirthDate',
          sections,
          result.birthDate + 'T00:00:00',
          setSections,
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error fetch data license PDF417 code:', error);
    }
  }
};

const fetchDataRUV = async (
  image: EventPhoto,
  urlImage: string,
  dispatch: DispatchFunction,
  setSections?: (sections: FieldSection[]) => void,
  sections?: FieldSection[],
) => {
  if (image.section === PhotoSection.RUV) {
    try {
      const result = await new ConnectServicesClient().extractRUV(urlImage);
      if (result) {
        const fieldsToDispatch = {
          brand: ActionType.SET_BRAND,
          model: ActionType.SET_MODEL,
          year: ActionType.SET_YEAR,
          color: ActionType.SET_COLOR,
          plate: ActionType.SET_PLATE,
          vin: ActionType.SET_VEHICLE_VIN,
          motor: ActionType.SET_VEHICLE_ENGINE,
        };

        for (const [field, actionType] of Object.entries(fieldsToDispatch)) {
          if (result[field]) {
            dispatch({
              type: actionType,
              payload: result[field],
            });

            setValueFieldName(
              fieldsName(field),
              sections,
              result[field],
              setSections,
            );
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error fetch data license PDF417 code:', error);
    }
  }
};

// Upload Images
export const uploadInspectionPhotos = async (
  images: EventPhoto[],
  countryCode: string | null,
  eventNumber: string | undefined,
  dispatch: DispatchFunction,
  setSections?: (sections: FieldSection[]) => void,
  sections?: FieldSection[],
  progressCallback?: (current: number, total: number) => void,
): Promise<any[]> => {
  try {
    if (countryCode && eventNumber) {
      axiosRetry(APIClient, {
        retries: 3,
        retryCondition: (_error) => true,
        retryDelay: (retryCount) => {
          return retryCount * 1000;
        },
      });

      const endpoint = endpoints.POST_PHOTO.replace('{eventId}', eventNumber);
      const result: EventPhoto[] = [];

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const data = {
          countryCode: countryCode,
          name: image?.imageName,
          file: image?.dataURL,
          type: 'jpg',
          sectionId: image?.section,
          applyResize: true,
          location: image?.location,
        };

        try {
          const response = await APIClient.post(endpoint, data);
          const objImage: any = response.data.data;
          let imageURI = '';

          if (typeof objImage === 'string') {
            imageURI = objImage;
          } else if (objImage.preSignedUrl) {
            imageURI = objImage.preSignedUrl;
          }

          await fetchDataDriverLicense(
            image,
            imageURI,
            countryCode,
            eventNumber,
            dispatch,
            setSections,
            sections,
          );

          await fetchDataRUV(image, imageURI, dispatch, setSections, sections);

          result.push({
            ...image,
            awsUrl: imageURI,
            dataURL: imageURI,
            isWaiting: imageURI === undefined || imageURI.length === 0,
            orientation: image.orientation,
          });

          if (progressCallback) {
            progressCallback(i + 1, images.length);
          }
        } catch (error) {
          console.error(`Error al subir la imagen ${i + 1}`, error);
          throw error;
        }
      }

      return result;
    }

    return [];
  } catch (error) {
    dispatch({
      type: ActionType.MESSAGE_ACTION,
      payload: 'Se encontraron problemas al subir la imagen.',
    });

    throw error;
  } finally {
    axiosRetry(APIClient, { retries: 0 });
  }
};

// Post Event Photos
export const saveEventPhoto = (
  photos: EventPhoto[],
  dispatch: DispatchFunction,
): void => {
  try {
    dispatch({ type: ActionType.SET_INSPECCION_IMAGES, payload: photos });
  } catch (error) {
    dispatch({
      type: ActionType.MESSAGE_ACTION,
      payload: 'Se encontraron problemas al guardar la información.',
    });
  }
};

// Get Transition
export const getEventTransitions = async (eventId: number): Promise<any> => {
  const {
    data: { data },
  } = await APIClient.get(endpoints.GET_TRANSITIONS, {
    params: { eventId },
  });

  return data;
};

export const getAuditLogs = async (eventId: number): Promise<any> => {
  const {
    data: { data },
  } = await APIClient.get(endpoints.GET_AUDIT_LOGS, {
    params: { eventId },
  });

  return data;
};

// Save transition
export const saveEventTransition = async (transitionData: {
  [key: string]: any;
}): Promise<AxiosResponse<any>> => {
  return await APIClient.post(endpoints.POST_TRANSITIONS, transitionData);
};

// Get Object to Save Inspection
export const toInspectionObject = (
  sections: FieldSection[],
  state: IInspectionState,
  isFinished: boolean,
  counterpart?: number,
  injuryNumber?: number,
): any => {
  const inspection: any = {};
  inspection.UserInspectorId = String(state.currentInspection?.UserInspectorId);

  if (Number.isInteger(injuryNumber)) {
    const numberPositionTemplate = String(
      'InjuredPersons[{index}].InjuryNumber',
    ).replace('{index}', '0');
    set(inspection, numberPositionTemplate, injuryNumber);
  }

  const validateDataType = (data: any, type: any) => {
    if (!type) {
      type = '';
    }

    switch (type.toLowerCase()) {
      case ValueTypes.INTEGER:
        return +data;
      case ValueTypes.STRING:
        return String(data);
      case ValueTypes.BOOLEAN:
        if (data === '1' || String(data).toLowerCase() === 'true') {
          return true;
        } else if (data === '2' || String(data).toLowerCase() === 'false') {
          return false;
        } else {
          return null;
        }
      default:
        return data !== undefined ? data : '';
    }
  };

  const sectionsForSearch = [...sections];

  sections.forEach((s) => {
    for (const f of s.fields as any[]) {
      if (f.name === 'coverageList' && state.currentInspection?.coverages) {
        // Build object to send to API
        f.tmpValue = JSON.stringify(
          state.currentInspection?.coverages.map((cov: any) => {
            return {
              EventId: state.currentInspection?.EventId,
              CoverageId: String(cov.coverageId),
            };
          }),
        );

        // Update values for control checkbox list
        f.value = JSON.stringify(
          state.currentInspection?.coverages.map((cov: any) => {
            return {
              id: cov.coverageId,
              name: cov.coverageName,
              state: true,
            };
          }),
        );
      }

      const isFieldForAE =
        String(f.type).toLowerCase() === FieldTypes.RADIOBUTTON &&
        s.formSectionId === 107;

      const isSinisterLocationFields = [
        'provinceDirectionSinister',
        'cantonDirectionSinister',
        'districtDirectionSinister',
        'placeDirectionSinister',
      ].includes(f.name);

      if (isSinisterLocationFields) {
        f.value =
          f.value !== null && f.value !== undefined ? String(f.value) : f.value;
      }

      if (
        !Number.isInteger(counterpart) &&
        !Number.isInteger(injuryNumber) &&
        !isFieldForAE &&
        !isSinisterLocationFields &&
        f.value === f.originalValue &&
        !f.isModifyForAudit
      ) {
        continue;
      }

      let value;
      if (Number.isInteger(counterpart)) {
        f.fieldPath = f.fieldPath.replace('{index}', counterpart);
      }

      if (Number.isInteger(injuryNumber)) {
        f.fieldPath = f.fieldPath.replace('{index}', '0');
      }

      if (f.fieldPath.includes('{index}')) {
        continue;
      }

      switch (f.type.toLowerCase()) {
        case FieldTypes.NUMBER:
          value = Number(f.value);
          break;
        case FieldTypes.SIGNATURE:
        case FieldTypes.STYLIZED_RADIOBUTTONS:
        case FieldTypes.RADIOBUTTON:
        case FieldTypes.DROPDOWN:
        case FieldTypes.TEXTAREA:
        case FieldTypes.TIME:
        case FieldTypes.DIAGRAM:
        case FieldTypes.DAMAGES_DIAGRAM:
        case FieldTypes.FILLED_TEXT:
        case FieldTypes.TEXT:
          value = validateDataType(f.value, f.sendFieldType);
          break;
        case FieldTypes.DATE: {
          value = validateDate(f.value);
          break;
        }
        case FieldTypes.CHECKBOX_LIST: {
          value = JSON.parse(f.tmpValue);
          break;
        }
        case FieldTypes.DYNAMIC_DROPDOWN: {
          value = '';
          if (f.value !== undefined && f.value !== null) {
            value = String(f.value).indexOf('null') < 0 ? f.value : '';
          }
        }
      }

      if (f.name === 'Hora') {
        const ampm = format(
          new Date(`${format(new Date(), 'yyyy-MM-dd')} ${f.value || ''}`),
          'aa',
        );
        if (ampm.toUpperCase() === 'AM') {
          set(inspection, 'datosDelAccidente.AM', 'X');
        } else {
          set(inspection, 'datosDelAccidente.PM', 'X');
        }
        const [hour, minutes] = f.value?.split?.(':');
        const _hour = Number(hour) === 0 ? '12' : hour;
        const _minutes = Number(minutes) < 10 ? `0${minutes}` : minutes;
        set(inspection, 'datosDelAccidente.Hora', `${_hour}:${_minutes}`);
      } else if (f.name === 'EstuvoPresentePolicia') {
        if (f.value === '1') {
          set(inspection, f.fieldPath, 'X');
        } else {
          set(inspection, 'datosDelPolicia.NoEstuvo', 'X');
        }
      } else if (
        f.name === 'IndInvestigation' ||
        f.fieldPath === 'IndPropietario'
      ) {
        set(inspection, f.fieldPath, f.value === 'true');
      } else if (
        f.name === 'Propietarioduenno' ||
        f.name === 'DepositoDirecto'
      ) {
        set(inspection, f.fieldPath, f.value === '1' ? '1' : '0');
      } else if (
        f.name === 'AseguradoEdadConductor' ||
        f.name === 'PerjudicadoEdadConductor' ||
        f.name === 'EdadDuenno' ||
        f.name === 'EdadDuenno2'
      ) {
        const age = -1 * +f.value;
        const newBirthDay = format(
          add(new Date(), { years: age }),
          'yyyy-MM-dd',
        );
        set(inspection, f.fieldPath, newBirthDay);
      } else if (
        String(f.fieldPath).indexOf('_Time') > 0 &&
        state.currentInspection
      ) {
        const splitKeyName = String(f.fieldPath).split('_');
        const referenceFieldDate = sectionsForSearch
          .filter((f) => f.formSectionId === s.formSectionId)[0]
          ?.fields.filter((f) => f.name === splitKeyName[0])[0];
        const splitBaseDate = referenceFieldDate
          ? String(referenceFieldDate.value).split('T')
          : [];
        let fieldDate: any = splitBaseDate.length > 0 ? splitBaseDate[0] : '';

        if (String(fieldDate).length > 0 && String(value).length > 0) {
          fieldDate = validateDate(fieldDate);
          set(inspection, splitKeyName[1], fieldDate + ' ' + value + ':00');
        }
      } else {
        set(inspection, f.fieldPath, value);
      }
    }
  });

  const linkPhotos: {
    eventId: number | undefined;
    imageUrl: string | undefined;
    description: string | undefined;
    imageSectionId: number;
    imageSource: string;
  }[] = [];

  // Validate Images
  state.currentImages.map((p: EventPhoto) => {
    const keySplit = state.currentInspection?.EventRecord
      ? state.currentInspection?.EventRecord + '/'
      : '';
    const description = p.awsUrl ? p.awsUrl?.split(keySplit) : [];
    const photo = {
      eventId: state.currentInspection?.EventId,
      imageUrl: p.awsUrl,
      description: description?.length > 1 ? description[1] : '',
      imageSectionId: +p.section,
      imageSource: 'SIC_' + localStorage.getItem('countryCode') || '',
    };

    if (photo?.imageSectionId > 0) {
      linkPhotos.push(photo);
    }

    return p;
  });

  // Validate Injured Persons
  if (inspection.InjuredPersons && state.currentInspection?.injuryPersons) {
    const listInjuries = state.currentInspection?.injuryPersons;
    inspection.InjuredPersons = Array.from(inspection.InjuredPersons)
      .filter((f: any) => !isNaN(f.InjuryId) && f.InjuryId > 0)
      .map((f: any) => {
        const injPerson = listInjuries.filter(
          (i) => i.injuryNumber === +f.InjuryNumber,
        )[0];

        return {
          ...f,
          InjuryId: injPerson ? injPerson.injuryId : 0,
        };
      });
  }

  // Validate coverages list
  if (
    !inspection['Coberturas'] &&
    state.currentInspection?.coverages &&
    state.currentInspection?.coverages.length > 0
  ) {
    inspection.Coberturas = state.currentInspection?.coverages.map((f) => {
      return {
        EventId: state.currentInspection?.EventId,
        CoverageId: String(f.coverageId),
      };
    });
  }

  // Validate Circunstances
  if (state.currentInspection?.consequence) {
    inspection.CodigoConsecuenciaAsegurado =
      state.currentInspection?.consequence.CodigoAsegurado;
    inspection.CodigoConsecuenciaTercero =
      state.currentInspection?.consequence.CodigoTercero;
    inspection.Placa1 = state.currentInspection?.consequence.Placa1;
    inspection.Aseguradora1 = state.currentInspection?.consequence.Aseguradora1;
    inspection.Responsable = state.currentInspection?.consequence.Responsable;
  }

  if (
    String(state.currentInspection?.CountryCode).toUpperCase() ===
    CountryCode.CRC
  ) {
    inspection.InspectorConfirmAAP = state.currentInspection?.confirmAAPForm;
    inspection.AapReasonId = state.currentInspection?.optionAAPForm
      ? +state.currentInspection?.optionAAPForm
      : 0;
    inspection.AapObservation = state.currentInspection?.optionTextAAPForm;
  }

  // Validate Thirds
  if (
    inspection.Third &&
    Array.from(inspection.Third).length > 0 &&
    inspection.ThirdAdditional &&
    Array.from(inspection.ThirdAdditional).length > 0
  ) {
    inspection.Third = Array.from(inspection.Third).filter(
      (f: any) =>
        f !== undefined &&
        f.EventThirdId !== undefined &&
        f.EventThirdId !== null &&
        f.EventThirdId > 0,
    );

    inspection.ThirdAdditional = Array.from(inspection.ThirdAdditional).filter(
      (f: any) =>
        f !== undefined &&
        f.EventThirdAdditionalId !== undefined &&
        f.EventThirdAdditionalId !== null &&
        f.EventThirdAdditionalId > 0,
    );
  }

  if (state.audioStoryUrl) {
    inspection.audioStoryUrl = state.audioStoryUrl;
  }

  if (state.currentInspection?.AcceptTermsEmail) {
    inspection.AcceptTermsEmail = state.currentInspection.AcceptTermsEmail;
  }

  if (state.currentInspection?.storyDetail) {
    inspection.EventStory = state.currentInspection.storyDetail;
  }

  if (state.currentInspection?.InsuredVehicle?.AudioOcurrido) {
    if (inspection.datosVehiculo1) {
      inspection.datosVehiculo1.AudioOcurrido =
        state.currentInspection.InsuredVehicle.AudioOcurrido;
    } else {
      inspection['datosVehiculo1'] = {};
      inspection.datosVehiculo1.AudioOcurrido =
        state.currentInspection.InsuredVehicle.AudioOcurrido;
    }
  }

  if (state.currentInspection?.InjuredVehicle?.AudioOcurrido) {
    if (inspection.datosVehiculo2) {
      inspection.datosVehiculo2.AudioOcurrido =
        state.currentInspection.InjuredVehicle.AudioOcurrido;
    } else {
      inspection['datosVehiculo2'] = {};
      inspection.datosVehiculo2.AudioOcurrido =
        state.currentInspection.InjuredVehicle.AudioOcurrido;
    }
  }

  if (state.currentInspection?.InsuredVehicle?.Ocurrido) {
    if (inspection.datosVehiculo1) {
      inspection.datosVehiculo1.Ocurrido =
        state.currentInspection.InsuredVehicle.Ocurrido;
    } else {
      inspection['datosVehiculo1'] = {};
      inspection.datosVehiculo1.Ocurrido =
        state.currentInspection.InsuredVehicle.Ocurrido;
    }
  }

  if (state.currentInspection?.InjuredVehicle?.VehicleDescription2) {
    if (inspection.datosVehiculo2) {
      inspection.datosVehiculo2.VehicleDescription2 =
        state.currentInspection.InjuredVehicle.VehicleDescription2;
    } else {
      inspection['datosVehiculo2'] = {};
      inspection.datosVehiculo2.VehicleDescription2 =
        state.currentInspection.InjuredVehicle.VehicleDescription2;
    }
  }

  inspection.CountryCode = state.currentInspection?.CountryCode;
  inspection.APIKey = state.currentInspection?.APIKey || undefined;
  inspection.EventRecord = state.currentInspection?.EventRecord;
  inspection.Estado = isFinished
    ? EVENT_STATUSES.COMPLETED.id
    : state.currentInspection?.EventStateId;
  inspection.linkPhotos = linkPhotos;
  inspection.IsFinished = isFinished;
  inspection.IsInspector = false;
  inspection.UserId = Number(localStorage.getItem('userId') ?? '0');

  return inspection;
};

// Process for send audit data to API
const sendAuditData = async (
  eventId: number,
  auditConfig: IAuditEvent,
  sections: FieldSection[],
) => {
  const currentSections = sections.filter(
    (f: FieldSection) => f.parentSectionId === auditConfig.auditSection,
  );
  const isAuditPhotoOptions =
    [14, 50].includes(auditConfig.auditSection) &&
    auditConfig.auditMessage.length > 0; // Photo Sections
  const auditListFields: any[] = [];
  const auditModel = {
    eventId: eventId,
    userId: Number(localStorage.getItem('userId')),
    sectionId: auditConfig.auditSection,
    noApplyChanges: auditConfig.auditNoApply,
    beginTime: auditConfig.auditTimeInit,
    endTime: auditConfig.auditTimeFini,
    effectiveTime: auditConfig.auditEffectiveTime,
    message: auditConfig.auditMessage,
    subSectionName: '',
    fieldIdName: !isAuditPhotoOptions ? '' : 'PhotoFields',
    fieldLabel: !isAuditPhotoOptions ? '' : auditConfig.auditMessage,
  };
  if (currentSections && !auditConfig.auditNoApply && !isAuditPhotoOptions) {
    // Get all fields with changes
    currentSections.forEach((section: FieldSection) => {
      auditModel.subSectionName = section.title;
      section.fields
        .filter((f: Field) => f.isModifyForAudit === true)
        .forEach((f: Field) => {
          const updateModel = {
            ...auditModel,
            fieldIdName: f.name,
            fieldLabel: f.label,
          };
          auditListFields.push(updateModel);
        });
    });
  } else {
    // It only defines that the section has been audited without changes or specific sections
    auditListFields.push(auditModel);
  }

  if (auditListFields.length > 0) {
    const uri = endpoints.POST_EVENT_AUDIT.replace(
      '{eventId}',
      String(eventId),
    );
    await APIClient.post(uri, auditListFields);
  }
};

// Save Inspection
export const saveInspection = async (
  sections: FieldSection[],
  state: IInspectionState,
  isFinsihed: boolean,
  isInspector: boolean | undefined,
  dispatch: DispatchFunction,
  options: any = {},
): Promise<any> => {
  const isAuditProcess =
    Number(localStorage.getItem('rolId')) === Rol.AUDIT && !isInspector;
  const modelName = state.currentInspection?.EventId + '_model_autosave';
  const parsedData = toInspectionObject(
    sections,
    state,
    isFinsihed,
    options.counterpart,
    options.injuryNumber,
  );
  try {
    if (
      parsedData.AccidentDateOccurredStr !== undefined &&
      parsedData.AccidentDateOccurredStr !== null
    ) {
      dispatch({
        type: ActionType.SET_SINISTER_DATE,
        payload: parsedData.AccidentDateOccurredStr,
      });
    }
    if (
      parsedData.eventDateSinister !== undefined &&
      parsedData.eventDateSinister !== null &&
      parsedData.timeSinister !== undefined &&
      parsedData.timeSinister !== null
    ) {
      dispatch({
        type: ActionType.SET_SINISTER_DATE,
        payload:
          parsedData.eventDateSinister + ' ' + parsedData.timeSinister + ':0',
      });
    }
    if (isFinsihed) {
      dispatch({ type: ActionType.SET_LOADING, payload: true });
    }

    if (isInspector !== undefined) {
      parsedData.IsInspector = isInspector;
    }

    // Validate if the save Queue have pending saves
    sicSaveQueue.processQueue();

    // Transform data text
    //const dataTransform = transformToUpperCase(parsedData);

    const { data: respData } = await APIClient.post(
      endpoints.POST_INSPECTION_SAVE,
      {
        ...parsedData,
        UserId: Number(localStorage.getItem('userId')) || 0,
        EventId: state.currentInspection?.EventId
          ? String(state.currentInspection?.EventId)
          : undefined,
        CodeInspector: localStorage.getItem('codeInspector') ?? undefined,
      },
    );

    if (isFinsihed) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }

    if (isAuditProcess && state.currentInspection?.EventId) {
      await sendAuditData(
        state.currentInspection?.EventId,
        state.auditEvent,
        sections,
      );
      dispatch({
        type: ActionType.SET_AUDIT_PROCESS_SECTION,
        payload: state.auditEvent.auditSection,
      });
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }

    return respData;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(
      'sic :: error :: response :: ',
      JSON.stringify(error?.response?.data),
    );
  }

  return null;
};

export const finishEvent = async (
  data: IEventData,
  executeIntegrations = true,
): Promise<any> => {
  try {
    const result = await APIClient.post(endpoints.POST_EVENT_FINISH, {
      EventId: String(data.event?.EventId),
      UserId: Number(localStorage.getItem('userId') ?? 0),
      EventRecord: data.event?.EventRecord,
      CountryCode: data.event?.CountryCode,
      Estado: TransitionStatesIds.COMPLETED,
      ExecuteIntegrations: executeIntegrations,
    });

    return result.data;
  } catch (error: any) {
    console.error('Error: finish event: ', error?.response?.data);
  }

  return false;
};

export const triggerProcess = (
  action: ITriggerAction,
  state: IInspectionState,
  dispatch: DispatchFunction,
): void => {
  try {
    switch (action) {
      case ITriggerAction.ROTATE_INSPECTION_IMAGES: {
        if (
          state.rotateImages &&
          state.rotateImages.filter(
            (f: IRotateImage) =>
              f.orientation !== null && f.imageURL.length > 0,
          ).length > 0
        ) {
          const resultProcess = requestChangeImageOrientantion(
            state.rotateImages,
          );
          dispatch({
            type: ActionType.SET_LIST_FOR_ROTATE_IMAGES,
            payload: { data: resultProcess, forceUpdate: true },
          });
        }
        break;
      }
      default:
        break;
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log(ex);
  }
};

export const saveSketch = async (
  values: { scene?: SceneTypes | null; coordinates?: string; croquis?: string },
  state: IInspection | null,
): Promise<any> => {
  // Validate if the save Queue have pending saves
  sicSaveQueue.processQueue();

  const { data: respData } = await APIClient.post(
    endpoints.POST_INSPECTION_SAVE,
    {
      ...values,
      CountryId: state?.CountryId,
      CountryCode: state?.CountryCode,
      EventRecord: state?.EventRecord,
      EventId: state?.EventId ? String(state?.EventId) : undefined,
    },
  );

  return respData;
};

// Download event
export const downloadEvent = async (
  eventRecord: string,
  countryCode: string,
  roleId: number,
  dispatch: DispatchFunction,
): Promise<void> => {
  setLoading(true, dispatch);

  // Force update images
  if (countryCode) {
    await getImages(countryCode, eventRecord, dispatch, true);
  }

  // Send download request
  if ([Rol.CUSTOMER, Rol.BROKER].includes(roleId)) {
    const uri = endpoints.GET_DOWNLOAD_EVENT;
    const response = await APIClient.get(
      uri
        .replace('{roleId}', roleId.toString())
        .replace('{eventRecord}', eventRecord),
      {
        responseType: 'arraybuffer',
      },
    );
    if (response) {
      const FileSaver = require('file-saver');
      const fileName = eventRecord + '.zip';
      const blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      FileSaver.saveAs(blob, fileName);
    }

    setLoading(false, dispatch);
  } else {
    setTimeout(async () => {
      const eventToDownload: EventDownloadEvent = {
        eventoRecord: eventRecord,
        roleId: roleId,
        url: '',
        fileName: '',
        message: '',
      };

      const response = await APIClient.post(
        endpoints.POST_DOWNLOAD_EVENT,
        eventToDownload,
      );
      if (response) {
        const FileSaver = require('file-saver');
        FileSaver.saveAs(
          response.data?.data?.fileName,
          response.data?.data?.fileName?.split('Reports/')[1],
        );
      }
      setLoading(false, dispatch);
    }, 1500);
  }
};

export const downloadEventFromAction = async (
  event: Event,
  _state: IInspectionState,
  dispatch: DispatchFunction,
): Promise<void> => {
  if (event !== undefined && event?.eventRecord && event?.countryCode) {
    downloadEvent(
      event?.eventRecord,
      event?.countryCode,
      Number(localStorage.getItem('rolId')),
      dispatch,
    );
  }
};

export const confirmActionIntegration = async (
  event: Event,
  _state: IInspectionState,
  dispatch: DispatchFunction,
): Promise<void> => {
  dispatch({
    type: ActionType.SET_INTEGRATION_CONFIRM_RESEND,
    payload: true,
  });

  dispatch({
    type: ActionType.SET_INTEGRATION_RESEND_ID_EVENT,
    payload: event.eventId,
  });
};

export const resendIntegration = async (
  eventId: number,
  dispatch: DispatchFunction,
): Promise<void> => {
  setLoading(true, dispatch);
  await APIClient.post(
    endpoints.POST_INTEGRATION_RETRY.replace('{eventId}', eventId.toString()),
  );
  setLoading(false, dispatch);

  dispatch({
    type: ActionType.SET_INTEGRATION_CONFIRM_RESEND,
    payload: false,
  });

  dispatch({
    type: ActionType.SET_INTEGRATION_RESEND_ID_EVENT,
    payload: undefined,
  });
};

// Generate consolidated zip for SURA
export const generateConsolidate = async (
  data: { [key: string]: any },
  dispatch: DispatchFunction,
): Promise<string> => {
  try {
    const response = await APIClient.post(endpoints.POST_DOWNLOAD_FILE, data);

    return response?.data?.data;
  } catch (error) {
    dispatch({
      type: ActionType.MESSAGE_ACTION,
      payload: 'Se encontraron problemas al guardar la información.',
    });

    return '';
  }
};

// Download report from dates range
export const downloadReport = async (
  data: { [key: string]: any },
  dispatch: DispatchFunction,
): Promise<string> => {
  try {
    const response = await APIClient.post(endpoints.POST_DOWNLOAD_REPORT, data);

    return response?.data?.data;
  } catch (error) {
    dispatch({
      type: ActionType.MESSAGE_ACTION,
      payload: 'Se encontraron problemas al guardar la información.',
    });

    return '';
  }
};

// Download report claim from dates range
export const downloadReportClaim = async (
  data: { [key: string]: any },
  dispatch: DispatchFunction,
): Promise<string> => {
  try {
    const response = await APIClient.post(
      endpoints.POST_DOWNLOAD_REPORT_CLAIMS,
      data,
    );

    return response?.data?.data;
  } catch (error) {
    // TODO: Add Sentry logger
    dispatch({
      type: ActionType.MESSAGE_ACTION,
      payload: 'Se encontraron problemas al guardar la información.',
    });

    return '';
  }
};
// Download report from dates range
export const downloadExpressAdjustReport = async (
  data: { [key: string]: any },
  dispatch: DispatchFunction,
): Promise<string> => {
  try {
    const response = await APIClient.post(
      endpoints.POST_DOWNLOAD_AE_REPORT,
      data,
    );

    return response?.data?.data;
  } catch (error) {
    dispatch({
      type: ActionType.MESSAGE_ACTION,
      payload: 'Se encontraron problemas al guardar la información.',
    });

    return '';
  }
};

// Get URL form event
export const getUrlByEvent = async (event: Event): Promise<void> => {
  const inspectorModeURL =
    window.location.origin +
    `/inspector/${localStorage.getItem('countryCode')}/${String(
      event.eventId,
    )}`;
  window.open(inspectorModeURL, '_blanky');
};

// Cancel Event
export const cancelEvent = async (
  eventId: number,
  state: IInspectionState,
  dispatch: DispatchFunction,
  fetchClaims?: boolean,
): Promise<void> => {
  setLoading(true, dispatch);

  const transition: CancelTransition = {
    EventId: eventId,
    Description: '',
    EventStateId: 5,
    UserCompanyId: Number(localStorage.getItem('userId') || ''),
    TransitionDate: new Date(),
    IsComentary: false,
    CountryCode: localStorage.getItem('countryCode') || 'PRI',
    CancelMessage: String(localStorage.getItem('cancelReason')),
  };
  await APIClient.post(endpoints.POST_TRANSITIONS, transition);

  setTimeout(
    async () =>
      await getInspections(state.requestParams, dispatch, fetchClaims),
    700,
  );
};

// Restore Event
export const restoreEvent = async (
  eventId: number,
  reason: string,
  state: IInspectionState,
  dispatch: DispatchFunction,
  fetchClaims?: boolean,
): Promise<void> => {
  setLoading(true, dispatch);

  const restoreData = {
    EventId: eventId,
    Description: reason,
    EventStateId: 0,
    UserCompanyId: Number(localStorage.getItem('userId') || ''),
    TransitionDate: new Date(),
    IsComentary: true,
    CountryCode: localStorage.getItem('countryCode') || 'PRI',
  };
  await APIClient.post(endpoints.POST_TRANSITIONS_RESTORE, restoreData);

  setTimeout(
    async () =>
      await getInspections(state.requestParams, dispatch, fetchClaims),
    700,
  );
};

// Get list images from EventId
export const getImages = async (
  countryCode: string,
  eventRecord: string,
  dispatch: DispatchFunction,
  forceUpdate?: boolean,
): Promise<EventPhoto[] | undefined> => {
  let endpoint = endpoints.GET_IMAGES.replace(
    '{countryCode}',
    countryCode,
  ).replace('{eventRecord}', `${eventRecord}`);

  if (forceUpdate === true) {
    endpoint = endpoint + '?forceUpdate=true';
  }

  const response = await APIClient.get(endpoint);

  if (response.data.data) {
    const photos: EventPhoto[] = response.data.data
      .map(
        (p: any) =>
          !p.expenseDocument &&
          !p.estimatedMitchellDocument &&
          p.imageSectionId !== PhotoSection.DETAIL_ESTIMATED && {
            awsUrl: p.imageUrl,
            section: p.imageSectionId,
            imageId: p.imageId,
            imageName: p.imageName,
            coInspectionUpload: p.coInspectioUpload,
            expenseDocument: p.expenseDocument,
            estimatedMitchellDocument: p.estimatedMitchellDocument,
            lastModified: new Date(p.lastModified),
            rotate: p.rotate ? p.rotate : 0,
          },
      )
      .sort((a: EventPhoto, b: EventPhoto) => {
        const dateA = a.lastModified ?? new Date(0);
        const dateB = b.lastModified ?? new Date(0);

        return a.section - b.section || dateA.getTime() - dateB.getTime();
      });

    dispatch({ type: ActionType.SET_INSPECCION_IMAGES, payload: photos });

    return photos;
  }
};

// Update the section for a image
export const updateImageSection = async (
  image: EventPhoto,
  eventRecord: number,
): Promise<any> => {
  const {
    data: { data },
  } = await APIClient.put(endpoints.PUT_IMAGE_SECTION, {
    ...image,
    eventRecord,
    countryCode: localStorage.getItem('countryCode'),
  });

  return data;
};

// Rotate the visualization for a image
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rotateImage = async (data: any): Promise<AxiosResponse> => {
  return await APIClient.put(endpoints.ROTATE_IMAGE, data);
};

// Show or not show the Spinner
export const setLoading = (
  value: boolean,
  dispatch: DispatchFunction,
): void => {
  dispatch({ type: ActionType.SET_LOADING, payload: value });
};

// Update AE indicator
export const updateExpressAdjust = async (
  eventId: number,
  newValue: boolean,
  dispatch: DispatchFunction,
): Promise<boolean> => {
  const response = await APIClient.patch(
    endpoints.PATCH_EXPRESS_ADJUST.replace(
      '{eventId}',
      String(eventId),
    ).replace('{expressAdjust}', String(newValue)),
  );

  if (response.data.success) {
    dispatch({ type: ActionType.SET_EXPRESS_ADJUST, payload: newValue });
  }

  return Boolean(response.data.data);
};

// Delete action for an Counterpart
export const deleteCounterpart = async (
  counterpartId: number,
): Promise<void> => {
  await APIClient.delete(
    endpoints.DELETE_COUNTERPART.replace(
      '{counterpartId}',
      String(counterpartId),
    ),
  );
};

// Delete action for an Injured Person
export const deleteInjuredPerson = async (index: number): Promise<void> => {
  const uri = endpoints.DELETE_INJURED_PERSON.replace(
    '{injuredId}',
    String(index),
  );
  await APIClient.delete(uri);
};

// Fetch agents by Country
export const fetchAgents = async (
  countryCode?: string,
): Promise<IUserModel[]> => {
  const isMultiCountry = Boolean(localStorage.getItem('isMultiCountry'));
  const agents: IUserModel[] = JSON.parse(
    localStorage.getItem('agents') || '[]',
  );

  if (agents.length) {
    return agents;
  }

  const {
    data: { data },
  } = await APIClient.get(endpoints.GET_AGENTS, {
    params: { countryCode },
  });

  localStorage.setItem('agents', JSON.stringify(data));

  return data;
};

export const getAgentsFilter = async (): Promise<Agent[]> => {
  const countryCode = localStorage.getItem('countryCode') || undefined;
  const _agents = await fetchAgents(countryCode);

  const agents = _agents.map(
    (a: IUserModel) =>
      ({
        id: a.userCompanyID,
        name: a.name,
        email: a.email,
      } as Agent),
  );

  return agents;
};

// Updated assigned events for User
export const updateAssignedUser = async (
  eventIds: number[],
  userId: number,
  agentId?: number | null,
): Promise<any> => {
  const {
    data: { data },
  } = await APIClient.patch(
    endpoints.PATCH_EVENT_AGENT.replace('{agentId}', String(agentId || null)) +
      '?userId=' +
      userId,
    eventIds,
  );

  return data;
};

// Link a Tag with an Event
export const linkTagWithEvent = async (
  eventId: number,
  tagId?: number | null,
): Promise<any> => {
  const {
    data: { data },
  } = await APIClient.post(
    endpoints.POST_EVENT_TAG.replace('{eventId}', String(eventId)).replace(
      '{tagId}',
      String(tagId || 0),
    ),
  );

  return data;
};

// Get responsable for AAP
export const getResponsableAAP = async (
  apiKey: string,
  insuredCode: string,
  thirdCode: string,
): Promise<string> => {
  const response = await APIClient.post(endpoints.GET_RESPOSABLE_AAP, {
    apiKey: apiKey,
    insuredCode: insuredCode,
    thirdCode: thirdCode,
  });

  return response.data;
};

// Get the grid columns configuration
export const getEventGridColumns = async (
  companyId: number,
  roleId: number,
  formType: FormType,
): Promise<any> => {
  const formTypeValue = Number(formType);
  const response = await APIClient.get(
    endpoints.GET_GRID_COLUMNS.replace('{companyId}', String(companyId))
      .replace('{roleId}', String(roleId))
      .replace('{formTypeId}', formTypeValue.toString()),
  );

  return response.data.data;
};

// Add new third in Inspection
export const addThirdToEvent = async (eventId: number): Promise<any> => {
  const {
    data: { data },
  } = await APIClient.post(
    endpoints.POST_EVENT_THIRD.replace('{eventId}', String(eventId)),
  );

  return data;
};

// Add new injured in Inspection
export const addInjuredToEvent = async (eventId: number): Promise<any> => {
  const {
    data: { data },
  } = await APIClient.post(
    endpoints.POST_EVENT_INJURED.replace('{eventId}', String(eventId)),
  );

  return data;
};

// Send request for active the inspector link
export const sendActiveLink = async (eventId?: number): Promise<boolean> => {
  const currentUserId = Number(localStorage.getItem('userId'));
  const isValid =
    eventId !== undefined && eventId !== null && currentUserId > 0;
  if (isValid) {
    await APIClient.patch(
      endpoints.PATCH_INSPECTION_ACTIVE_LINK.replace(
        '{id}',
        String(eventId),
      ).replace('{userId}', String(currentUserId)),
    );
  }

  return isValid;
};

// Save Event Log // Bitacora
export const saveEventLog = async (
  eventId: number,
  userId: number,
  message: string,
): Promise<AxiosResponse<any>> => {
  return await APIClient.post(endpoints.POST_EVENT_LOG, {
    eventId: eventId,
    userId: userId,
    message: message,
  });
};

// Get ServiceTypeByInspection
export const getServiceType = async (id: number): Promise<any> => {
  const response = await APIClient.get(endpoints.GET_SERVICETYPE, {
    params: { id },
  });

  return response.data.data;
};

// Save ServiceTypeByInspection
export const saveServiceType = async (
  eventId: number,
  indObligatoryAdjust: boolean | undefined,
  indVirtualCallInspection: boolean | undefined,
  indConnectInspectorInspection: boolean | undefined,
  indConnectAdjustInspection: boolean | undefined,
  indCsmInspection: boolean | undefined,
  indRefund: boolean | undefined,
): Promise<void> => {
  const model = {
    eventId: eventId,
    indObligatoryAdjust: indObligatoryAdjust,
    indVirtualCallInspection: indVirtualCallInspection,
    indConnectInspectorInspection: indConnectInspectorInspection,
    indConnectAdjustInspection: indConnectAdjustInspection,
    indCsmInspection: indCsmInspection,
    indRefund: indRefund,
  };
  await APIClient.post(endpoints.POST_SERVICETYPE, model);
};

// Send Events CSM
export const transferEstimateCSM = async (
  queryFilters: Params,
  showMyServices: boolean,
  dispatch: DispatchFunction,
): Promise<void> => {
  setLoading(true, dispatch);
  await APIClient.post(`${endpoints.POST_PROCESS_CSM}?forceExecution=true`);
  await getInspections({ ...queryFilters, showMyServices }, dispatch);
  setLoading(false, dispatch);
};

//Get data claim forms
export const getClaimTrackerById = async (
  ClaimUId: string,
): Promise<IRequestForm> => {
  return await new ClaimTrackerClient().getClaimTracker(ClaimUId);
};

// Get ServiceTypeByInspection
export const getFraud = async (eventId: string): Promise<any> => {
  const endpoint = endpoints.GET_FRAUD.replace('{eventId}', eventId);
  const response = await APIClient.get(endpoint);

  return response.data.data;
};

// Get document types
export const getDocumentTypes = async (): Promise<DocumentType[]> => {
  const documentTypes = await APIClient.get(endpoints.GET_DOCUMENT_TYPES);

  return documentTypes?.data?.data;
};

// Get note types
export const getNoteTypes = async (): Promise<NoteType[]> => {
  const noteTypes = await APIClient.get(endpoints.GET_NOTE_TYPES);

  return noteTypes?.data?.data;
};

export const getFraudScan = async (claimNumber: string): Promise<any> => {
  const endpoint = endpoints.GET_FRAUD_SCAN.replace(
    '{claimNumber}',
    claimNumber,
  );
  const response = await APIClient.get(endpoint);

  return response.data.data;
};

export const getEventThirds = async (
  countryCode: string,
  eventId: number,
): Promise<any> => {
  const endpoint = endpoints.GET_EVENT_THIRDS.replace(
    '{countryCode}',
    countryCode,
  ).replace('{eventId}', eventId.toString());

  const response = await APIClient.get(endpoint);

  return response.data?.data;
};

export const createSufix = async (
  eventRecord: string,
  thirdId: number,
): Promise<any> => {
  const response = await APIClient.post(endpoints.POST_CREATE_SUFIX_CLAIM, {
    eventRecord,
    thirdId,
  });

  return response.data?.data;
};
