import CrFlagIcon from '../assets/icons/flags/cr-flag.svg';
import CoFlagIcon from '../assets/icons/flags/co-flag.svg';
import PanFlagIcon from '../assets/icons/flags/pan-flag.svg';
import PrFlagIcon from '../assets/icons/flags/pr-flag.svg';

export enum EventType {
  INSPECTION = 0,
  PRE_INSPECTION = 1,
}

export enum GalleryType {
  INSURED = 'INSURED',
  INJURED = 'INJURED',
}

export enum Brands {
  SURA = 'sura',
  CONNECT = 'connect',
  CSM = 'csm',
  LAFISE = 'lafise',
  OCEANICA = 'oceanica',
}

export const CompanyFolderRoot = [
  {
    folder: 'ISeguros',
    key: '877EEEE1-1E8F-406C-8AA4-19D087482452',
  },
  {
    folder: 'Connect',
    key: '974e2998-6a91-4e7f-ae70-12b1543c9b84',
  },
  {
    folder: 'Sura',
    key: '04EACE32-8B87-4755-9255-FF7A836DA733',
  },
  {
    folder: 'BCR',
    key: 'e8edfd4e-8a55-443b-9624-10977b2cb40b',
  },
  {
    folder: 'INS',
    key: '1500fc1b-e2a4-4d47-89fe-fc835177bd68',
  },
];

export enum BrandsId {
  SURA = 15,
  CSM = 11,
  LAFISE = 6,
  OCEANICA = 4,
  OPTIMA = 16,
  ASSA = 2,
  QUALITAS = 1,
  INTER_DE_SEGUROS = 7,
  GENERAL_DE_SEGUROS = 8,
  NACIONAL_DE_SEGUROS = 18,
  PREMIER = 25,
  ACERTA = 27,
  BANESCO = 28,
  OPTIMA_SEGUROS = 29,
  FEDPA = 32,
  BCR = 33,
  MULTINATIONAL = 34,
  CONNECT_CR = 3,
  CONNECT_PAN = 13,
  CONNECT_PRI = 14,
  CONNECT_CO = 31,
  CONNECT_MULTICOUNTRY = 36,
  SEGUROS_MUNDIAL = 30,
  INS = 42,
}

export enum InspectionDataSections {
  ACCIDENT = 'ACCIDENT',
  POLICE = 'POLICE',
  INSURED_OWNER = 'INSURED_OWNER',
  INSURED_DRIVER = 'INSURED_DRIVER',
  INSURED_VEHICLE = 'INSURED_VEHICLE',
  INJURED_OWNER = 'INJURED_OWNER',
  INJURED_DRIVER = 'INJURED_DRIVER',
  INJURED_VEHICLE = 'INJURED_VEHICLE',
  STATUS = 'STATUS',
  SKETCH = 'SKETCH',
  DEPOSITO_DIRECTO = 'DEPOSITO_DIRECTO',
  INSPECTOR = 'INSPECTOR',
  GLOBAL = 'GLOBAL',
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
  PHOTOS = 'PHOTOS',
  THIRDS = 'THIRDS',
  INJURIES = 'INJURIES',
  RESPONSABLE_A = 'RESPONSABLE_A',
  RESPONSABLE_B = 'RESPONSABLE_B',
  OPTIMA_ACCIDENT = 'OPTIMA_ACCIDENT',
  OPTIMA_OWNER_A = 'OPTIMA_OWNER_A',
  OPTIMA_OWNER_B = 'OPTIMA_OWNER_B',
  SURA_ACCIDENT = 'SURA_ACCIDENT',
  AAP = 'AAP',
  CIRCUNSTANCES = 'CIRCUNSTANCES',
  RESPONSABILITY = 'RESPONSABILITY',
  QUALITAS = 'QUALITAS',
  PRE_PHOTOS = 'PRE_PHOTOS',
  PRE_TERMS = 'PRE_TERMS',
  PRE_RESUME = 'PRE_RESUME',
  PRE_DETECT_DAMAGE = 'PRE_DETECT_DAMAGE',
  PRE_ALERTS = 'PRE_ALERTS',
  PRE_STATUS = 'PRE_STATUS',
}

export enum CountryCode {
  PRI = 'PRI',
  CRC = 'CRC',
  PAN = 'PAN',
  COL = 'COL',
}

export enum CountryIds {
  CRC = 1,
  PAN = 2,
  PRI = 4,
}

export const CountryList = [
  { id: 1, name: 'Costa Rica', code: 'CRC', icon: CrFlagIcon },
  { id: 2, name: 'Panamá', code: 'PAN', icon: PanFlagIcon },
  { id: 4, name: 'Puerto Rico', code: 'PRI', icon: PrFlagIcon },
  { id: 5, name: 'Colombia', code: 'COL', icon: CoFlagIcon },
];

export enum Rol {
  ADMIN = 1,
  INSPECTOR = 2,
  INSURER = 3,
  BROKER = 4,
  SUPERVISOR = 5,
  PRE_ADMIN = 6,
  PRE_BROKER = 7,
  PRE_INSURER = 8,
  AUDIT = 9,
  CUSTOMER = 10,
  ADJUSTER = 11,
  PRE_CONSULTANT = 12,
  CLAIMS_ASSISTANT = 13,
}

export enum FormType {
  INSPECTION = 1,
  PRE_INSPECTION = 2,
  VIRTUAL_INSPECTOR = 3,
  AJUSTE_EXPRESS = 4,
  PRE_INSPECTION_LINK = 5,
  CLAIM_MANAGEMENT = 6,
  CLAIM_MANAGEMENT_VI = 7,
  INSPECTOR_FORM = 8,
}

export enum FormActiveType {
  ACTIVE = 1,
  REQUEST_ACTIVATION = 2,
}

export interface ICheckListModel {
  id: number;
  name: string;
  state: boolean;
}

export interface IGrid {
  columnId: number;
  columnDataName: string;
  columnName: string;
  sortId: number;
}

export enum ResourceAction {
  CHANGE_EVENTSTATE = 'CHANGE_EVENTSTATE',
  USE_FILTERS = 'USE_FILTERS',
  USE_FILTER_MY_SERVICES = 'USE_FILTER_MY_SERVICES',
  USE_FILTER_INSPECTORS = 'USE_FILTER_INSPECTORS',
  USE_FILTER_COMPANIES = 'USE_FILTER_COMPANIES',
  USE_FILTER_RUNNERS = 'USE_FILTER_RUNNERS',
  USE_FILTER_AGENTS = 'USE_FILTER_AGENTS',
  USE_FILTER_OTHER_STATUS = 'USE_FILTER_OTHER_STATUS',
  USE_PREINSPECT_FILTERS = 'USE_PREINSPECT_FILTERS',
  USE_LINK_FORM = 'USE_LINK_FORM',
  USE_ASSIGN_AGENT = 'USE_ASSIGN_AGENT',
  USE_BTN_CANCEL_EVENT = 'USE_BTN_CANCEL_EVENT',
  USE_BTN_DOWNLOAD_EVENT = 'USE_BTN_DOWNLOAD_EVENT',
  USE_BTN_INTEGRATION_EVENT = 'USE_BTN_INTEGRATION_EVENT',
  USE_BTN_CREATE_SUFIX_EVENT = 'USE_BTN_CREATE_SUFIX_EVENT',
  USE_BTN_PROCESS_EVENT = 'USE_BTN_PROCESS_EVENT',
  USE_BTN_ADD_OBSERV = 'USE_BTN_ADD_OBSERV',
  USE_COLUMN_ACTIONS = 'USE_COLUMN_ACTIONS',
  LOG_STATE_TO_COMPLETE = 'LOG_STATE_TO_COMPLETE',
  LOG_STATE_ONLY_TERMINATE = 'LOG_STATE_ONLY_TERMINATE',
  MENU_ADMIN_USERS = 'MENU_ADMIN_USERS',
  MENU_ACCESS_REPORT = 'MENU_ACCESS_REPORT',
  MENU_ACCESS_INSPECTIONS = 'MENU_ACCESS_INSPECTIONS',
  MENU_ACCESS_PREINSPECTIONS = 'MENU_ACCESS_PREINSPECTIONS',
  MENU_CREATE_PREINSPECTIONS = 'MENU_CREATE_PREINSPECTIONS',
  MENU_DAMAGE_PARAMETERS = 'MENU_DAMAGE_PARAMETERS',
  VIEW_EVENT_OBSERVATIONS = 'VIEW_EVENT_OBSERVATIONS',
  VIEW_NOTIFICATIONS = 'VIEW_NOTIFICATIONS',
  VIEW_EVENT_DETAIL = 'VIEW_EVENT_DETAIL',
  VIEW_TAGS = 'VIEW_TAGS',
  VIEW_EVENT_TABS = 'VIEW_EVENT_TABS',
  VIEW_EVENT_READ_ONLY = 'VIEW_EVENT_READ_ONLY',
  VIEW_CLAIM_ADJUST_SCREEN = 'VIEW_CLAIM_ADJUST_SCREEN',
}

export enum DropDownTypes {
  INSPECTORS = '{INSPECTORS}',
  PROVINCES = '{PROVINCE}',
  CANTONS = '{CANTON}',
  DISTRICTS = '{DISTRICT}',
}

export enum CheckBoxTypes {
  COVERAGES = '{COVERAGE}',
  COVERAGES_QUALITAS = '{COVERAGE_QUALITAS}',
}
export interface ITransition {
  Name: string;
  Description: string;
  TransitionDate: string;
}

export enum ITriggerAction {
  ROTATE_INSPECTION_IMAGES = 1,
  ROTATE_PREINSPECTION_IMAGES = 2,
}

export interface IRotateImage {
  imageName: string;
  imageURL: string;
  orientation: any;
}

export enum TransitionStatesIds {
  CREATED = 1,
  COMPLETED = 2,
  PROCESSED = 3,
  FINISHED = 4,
  CANCELLED = 5,
  AUTHORIZED = 6,
  ELIMINATED = 7,
  ABOUT_TO_SEND = 8,
  COMMENT = 9,
  PENDING = 10,
  EXPIRED = -3,
}

export enum MetadataTag {
  CLIENT_COINSPECTION = 'CLIENT_COINSPECTION',
  PHOTO_NO_VALID = 'PHOTO_NO_VALID',
  CMT_DOCUMENTS = 'CMT_DOCUMENTS',
  CMT_EXPENSE_DOCUMENT = 'CMT_EXPENSE_DOCUMENT',
  CMT_ESTIMATED_MITCHELL_DOCUMENT = 'CMT_ESTIMATED_MITCHELL_DOCUMENT',
}

export interface DocumentType {
  id: number;
  name: string;
  color: string;
}

export interface NoteType {
  id: number;
  name: string;
  type: string;
}

export interface Paginator {
  rows: number;
  initial: number;
  final: number;
}

export const CmtCountries: CountryCode[] = [
  CountryCode.CRC,
  CountryCode.PAN,
  CountryCode.PRI,
  CountryCode.COL,
];

export const AccountsEnableEditInfo: BrandsId[] = [];

export const phoneMasks = {
  CRC: '+506 99999999', // Costa Rica
  PAN: '+507 99999999', // Panamá
  COL: '+57 9999999999', // Colombia
  PRI: '+1 9999999999', // Puerto Rico
  MEX: '+52 9999999999', // México
};

export const codeMask = {
  MFA: '9   9   9   9   9   9',
};
